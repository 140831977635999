.ui.form .field input[readonly] {
  pointer-events: none;
  opacity: .45;
}

.ui.labeled.input:not([class*="corner labeled"]) input.currency-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ui.icon.input > .pe{
  position: absolute;
  top: 2px;
}
.ui.transparent[class*="left icon"].input > input{
  padding-left: 2.3em !important;
}

.ui.selection.dropdown {
  min-width: unset;
}

.main.button{
  color: #fff;
  background: $maincolor;
}
.main.button:hover{
  color: #fff;
  background: $maincolor-hover;
}


table .ui.checkbox .box:before,
table .ui.checkbox label:before {
  top: -1px;
  left: -1px;
}


.combodate select{
  display: inline-block !important;
}

ul.pagination {
  list-style: none;

  & > li{
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    width: 35px;
    height: 35px;
    border: 2px solid #f88457;
    border-radius: 50%;
    text-align: center;
    padding-top: 4px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;

    & > a, & > span{
      color: #f88457;
      font-size: 14px;
      padding: 12px;
    }

    &.disabled{
      border: 2px solid #d4d4d5;
      & > a, & > span{
        color: #d4d4d5;
      }
      &:hover{
        cursor: not-allowed;
      }
    }

    &.active{
      background: #f88457;
      & > a, & > span{
        color: #fff;
      }
    }

    &:hover{
      color: #fff;
      &:not(.disabled){
        background: #f88457;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        & > a, & > span{
          color: #fff;
        }
      }
    }
  }
}
