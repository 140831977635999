img{
  -webkit-user-drag: none;
     -moz-user-drag: none;
      -ms-user-drag: none;
          user-drag: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  &.cover{
	-webkit-object-fit: cover;
	   -moz-object-fit: cover;
	    -ms-object-fit: cover;
          object-fit: cover;
  }
  &.circle{
	  border-radius: 50%;
  }
}

.middle{
  vertical-align: middle !important;
}

a, button{
  @include ease-transition(0.3s);
  &:hover{
    @include ease-transition(0.3s);
  }
}

.full.width{
  width: 100% !important;
}

.clearfix {
  clear: both;
}


.text{
  &.uppercase{
    text-transform: uppercase !important;
  }
  &.lowercase{
    text-transform: lowercase !important;
  }
  
  &.del{
    text-decoration: line-through !important;
  }

  &.light{
    font-weight: 300 !important;
  }
  &.normal{
    font-weight: 400 !important;
  }
  &.strong{
    font-weight: 700;
  }

  &.black{
    color: #343434 !important;
  }
  &.white{
    color: #fff !important;
  }
  &.gray{
    color: #6f7986 !important;
  }
  &.red{
    color: #e74c3c !important;
  }
  &.green{
    color: #1abc9c !important;
  }

  &.left{
    text-align: left !important;
  }
  &.center{
    text-align: center !important;
  }
  &.right{
    text-align: right !important;
  }
}

.margin{
  &.auto{
    margin: auto !important;
  }
  &.b0{
    margin-bottom: 0 !important;
  }
  &.t0{
    margin-top: 0 !important;
  }
  &.t3{
    margin-top: 3px !important;
  }
  &.b3{
    margin-bottom: 3px !important;
  }
  &.t5{
    margin-top: 5px !important;
  }
  &.b5{
    margin-bottom: 5px !important;
  }
  &.t10{
    margin-top: 10px !important;
  }
}
.padding{
  &.b0{
    padding-bottom: 0 !important;
  }
  &.t0{
    padding-top: 0 !important;
  }
  &.t5{
    padding-top: 5px !important;
  }
  &.b5{
    padding-bottom: 5px !important;
  }
}


.opacity{
  &.o2{
    opacity: .2;
  }
}

.border{
  &.bb1{
    border-bottom-width: 1px !important;
  }
}
