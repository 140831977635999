/* Mobile Only Hide */

@media only screen and (max-width: 767px) {
  .mobile.hide{
    display: none !important;
  }
}


/* Tablet Only Hide */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet.hide{
    display: none !important;
  }
}


/* Computer Only Hide */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .computer.hide{
    display: none !important;
  }
}


/* Large Screen Only Hide */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .computer.hide{
    display: none !important;
  }
}


/* Widescreen Only Hide */

@media only screen and (min-width: 1920px) {
  .computer.hide{
    display: none !important;
  }
}
