$font-stack: 'Nunito', 'Open Sans', 'Sans-Sherif';
$font-icon: 'Pe-icon-7-stroke';

$maincolor: #21a2b7;
$maincolor-hover: #168799;

$sidebar: #343b42;
$sidebar-lint: #398adb;
$sidebar-icon: #212529;
$sidebar-icon-lint: #2b7bbb;

$popup-action: #f8f8fc;
$popup-action-text: #b6b5c5;

$submenu: #ffffff;
$submenu-color: #676767;
$submenu-color-active: #ffffff;
$submenu-lint: #398adb;

$datatable-search: #f8f8fc;
$datatable-search-text: #b6b5c5;

$paging: #f8f8fc;
$paging-text: #343434;
$paging-hover: #b6b5c5;
$paging-hover-text: #ffffff;


@mixin transition($time) {
  -webkit-transition: all $time !important;
     -moz-transition: all $time !important;
      -ms-transition: all $time !important;
          transition: all $time !important;
}
@mixin ease-transition($time) {
  -webkit-transition: all $time ease !important;
     -moz-transition: all $time ease !important;
      -ms-transition: all $time ease !important;
          transition: all $time ease !important;
}


body{
  font-family: $font-stack;
  font-weight: 400;
  color: #343434;
  font-size: 14px;
  background: #f6f6f6;
}


@import 'util';
@import 'responsive';


.ui.menu,
.ui.button,
.ui.accordion .title:not(.ui){
  font-family: $font-stack;
}

h1, h2, h3, h4, h5, h6, strong,
.ui.header{
  font-weight: 700;
  font-family: $font-stack;
}

.ui.form .fields:not(.inline) label{
  margin-top: 7px;
}

.ui.table{
  border: 0;
  border-bottom: 1px solid rgba(34, 36, 38, .15);
  border-radius: 0;
  width: 100% !important;
  overflow: hidden;

  thead tr:first-child>th:first-child,
  tfoot tr:first-child>th:first-child,
  tr:first-child>th:last-child {
    border-radius: 0 0 0 0 !important;
  }

  thead th {
    //border-bottom: 3px solid $maincolor;
    background-color: $maincolor;
    color: #fff;
  }

  tfoot th {
    background: #ffffff;
  }

  .thick.border {
    border-top: 1px solid #ababab;
    border-bottom: 1px solid #ababab;
  }
}
.ui.definition.table {
  thead:not(.full-width) {
    th:first-child {
      background: #37474f !important;
      color: #fff;
      font-weight: 700;
    }
    th:nth-child(2) {
      border-left: 0;
    }
  }

  tr {
    td {
      &:first-child {
        background: #37474f !important;
        color: #fff !important;
      }
    }
  }
}

/* Custom DataTables Style */
table.dataTable {
  &.no-footer {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }
}
table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background-color: #ecf0f1;
}

.dataTables_wrapper {
  .dataTables_length {
    select {
      border: 1px solid rgba(34, 36, 38, .15) !important;
      background: #fff;
    }
  }

  .dataTables_filter {
    color: $datatable-search-text !important;

    input[type=search] {
      background: $datatable-search;
      border: 0;
      border-radius: 20px;
      padding: 7px 15px;

      &:focus {
        outline: none !important;
      }
    }
  }

  .dt-buttons {
    margin-bottom: 10px;

    .dt-button {
      background: #2185d0;
      border: 1px solid #2185d0;
      color: #fff;
      padding: .4em 1.2em;

      &:hover,
      &:focus,
      &:active {
        background: #1678c2 !important;
        border: 1px solid #1678c2 !important;
        color: #fff !important;
      }
    }
  }


  .dataTables_paginate .paginate_button {
    background: $paging;
    border: 1px solid $paging;
    color: $paging-text !important;
    border-radius: 50%;
    width: 35px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    &.disabled {
      background: $paging;
      border: 1px solid $paging;
      color: #dedede !important;

      &:hover {
        background: $paging;
        border: 1px solid $paging;
        color: #dedede !important;
      }
    }

    &:hover {
      background: $paging-hover;
      border: 1px solid $paging-hover;
    }

    &.current {
      background: $paging-hover;
      border: 1px solid $paging-hover;
      color: $paging-hover-text !important;

      &:hover {
        background: $paging-hover;
        border: 1px solid $paging-hover;
        color: $paging-hover-text !important;
      }
    }
  }
}


/* Mini Modal */
.ui.mini.modal > .header:not(.ui) {
  font-size: 1.3em;
}

@media only screen and (max-width: 767px) {
  .ui.mini.modal {
    width: 95%;
    margin: 0em 0em 0em -47.5%;
  }
}
@media only screen and (min-width: 768px) {
  .ui.mini.modal {
    width: 35.2%;
    margin: 0em 0em 0em -17.6%;
  }
}
@media only screen and (min-width: 992px) {
  .ui.mini.modal {
    width: 340px;
    margin: 0em 0em 0em -170px;
  }
}
@media only screen and (min-width: 1200px) {
  .ui.mini.modal {
    width: 360px;
    margin: 0em 0em 0em -180px;
  }
}
@media only screen and (min-width: 1920px) {
  .ui.mini.modal {
    width: 380px;
    margin: 0em 0em 0em -190px;
  }
}

/* Tiny Modal */
.ui.small.modal > .header:not(.ui) {
  font-size: 1.3em;
}

@media only screen and (max-width: 767px) {
  .ui.tiny.modal {
    width: 95%;
    margin: 0em 0em 0em -47.5%;
  }
}
@media only screen and (min-width: 768px) {
  .ui.tiny.modal {
    width: 52.8%;
    margin: 0em 0em 0em -26.4%;
  }
}
@media only screen and (min-width: 992px) {
  .ui.tiny.modal {
    width: 510px;
    margin: 0em 0em 0em -255px;
  }
}
@media only screen and (min-width: 1200px) {
  .ui.tiny.modal {
    width: 540px;
    margin: 0em 0em 0em -270px;
  }
}
@media only screen and (min-width: 1920px) {
  .ui.tiny.modal {
    width: 570px;
    margin: 0em 0em 0em -285px;
  }
}


.sweet-alert {
  margin-left: -239px;
}


.sidebar.toggle{
  cursor: pointer;
}



.mainbody{
  padding: 75px 20px 20px 20px;
}

@media only screen and (max-width: 767px) {
  .mainbody{
    padding: 65px 10px 10px 10px;
  }
}


/* MENU */
@import 'menu';


.base.content{
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 30px;
  position: relative;

  .title{
    text-transform: uppercase;
    color: #343434;
  }
  .inside{
    .popup-action{
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 20px;
      background: $popup-action;
      color: $popup-action-text;
    }
  }
  .toolbar{
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    a{
      color: #454545;
    }
  }
}


.statistics-icon {
  position: absolute;
  z-index: 0;
  right: 10px;
  top: 10px;

  i {
    font-size: 100px;
    color: #f2f2f2;
  }

  & + .ui.statistic {
    position: relative;
    z-index: 1;
  }
}

/* FORM & BUTTON */
@import 'form';


ul.report-menu {
  list-style: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;

  & > li{
    border-bottom: 1px solid #dedede;

    &:first-child{
      border-top: 1px solid #dedede;
    }

    & > a{
      display: block;
      position: relative;
      color: #6f7986;
      font-size: 12px;
      font-family: $font-stack;
      text-transform: uppercase;
      @include transition(0.3s);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 10px 0 10px 10px;
    }

    &.active,
    &:hover{
      @include transition(0.3s);

      & > a{
        background: $sidebar-lint;
        color: #ffffff;
      }
    }
  }
}


.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active, .ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  color: #454545;
}

.ui-datepicker .ui-datepicker-calendar .ui-state-highlight a {
  background: $sidebar-lint;
}
