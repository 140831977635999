.ui.inverted.menu{
  background: $maincolor !important;

  .item{
    color: #ffffff;

    &:before {
      background: transparent;
    }
  }
}

.ui.left.demo.vertical.inverted.sidebar.overlay.visible {
  background-color: $sidebar;
  background: $sidebar;
}


.menu-alert {
  white-space: nowrap;
  overflow:hidden !important;
  text-overflow: ellipsis;
}


.menu-panel {
  border: 1px solid #FFFFFF;
  transition: 0.5s;

  .fa {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.5s;
  }

  .panel-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
    color: #343434;
  }

  &:hover {
    border: 1px solid $maincolor;
    transition: 0.5s;

    .fa {
      -ms-transform: rotate(17deg);
      -webkit-transform: rotate(17deg);
      transform: rotate(17deg);
      transition: 0.5s;
    }

    .panel-title {
      color: $maincolor;
    }
  }
}

.info-box {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  border-radius: 2px;

  .info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0,0,0,0.2);
    color: #ffffff;
  }

  .info-box-content {
    padding: 15px;
    margin-left: 90px;

    .info-box-number {
      display: block;
      font-weight: bold;
      font-size: 18px;
    }

    .info-box-text {
      // text-transform: uppercase;
      display: block;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;
      color: #565656;
    }
  }
}
